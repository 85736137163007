@import '../../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.css';


.react-datepicker-wrapper {
    display: initial;
}

.react-datepicker__tether-element {
    z-index: 1702 !important;
}

.react-datepicker {
    font-size: 1.3rem !important;
}

.react-datepicker__current-month {
    font-size: 1.5rem !important;
}

.react-datepicker__header {
    padding-top: 6px !important;
}

.react-datepicker__navigation {
    top: 13px !important;
}
.react-datepicker__navigation--years-upcoming {
    top: 0px !important;
    border-bottom-color: #675c5c;
}
.react-datepicker__navigation--years-previous {
    top: 0px !important;
    border-top-color: #675c5c;
}

.react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
}

.react-datepicker__input-container {
    width: 100%;
    display: table;
}

.form-inline .react-datepicker__input-container {
        width: auto;
        display: inline;
    }

.react-datepicker__close-icon {
    margin-right: -50px;
    z-index: 5 !important;
}

ul.filter-criteria > li {
    vertical-align: top;
}


table .sort {
    cursor: pointer;
    cursor: hand;
}

table .sort-none:after {
    content: "\f0dc";
    font-family: FontAwesome;
    margin-left: 5px
}


table .sort-desc:after {
    content: "\f0dd";
    font-family: FontAwesome;
    margin-left: 5px
}

table .sort-asc:after {
    content: "\f0de";
    font-family: FontAwesome;
    margin-left: 5px
}

.form-inline .form-control {
    width: auto;
}

.form-inline .form-group {
    display: inline-block;
}

.top-padding {
    margin-top: 20px;
}

.center-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    bottom: 0;
    margin: auto;
}

.select-button-toolbar {
    margin-left: 5px
}

.btn-pure,
.btn-pure:hover,
.btn-pure:focus,
.btn-pure:active,
.btn-pure.active,
.open > .dropdown-toggle.btn-pure,
.btn-pure[disabled],
fieldset[disabled] .btn-pure {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

    .btn-pure:hover,
    .btn-pure:hover:hover,
    .btn-pure:focus:hover,
    .btn-pure:active:hover,
    .btn-pure.active:hover,
    .open > .dropdown-toggle.btn-pure:hover,
    .btn-pure[disabled]:hover,
    fieldset[disabled] .btn-pure:hover,
    .btn-pure:focus,
    .btn-pure:hover:focus,
    .btn-pure:focus:focus,
    .btn-pure:active:focus,
    .btn-pure.active:focus,
    .open > .dropdown-toggle.btn-pure:focus,
    .btn-pure[disabled]:focus,
    fieldset[disabled] .btn-pure:focus,
    .btn-pure.focus,
    .btn-pure:hover.focus,
    .btn-pure:focus.focus,
    .btn-pure:active.focus,
    .btn-pure.active.focus,
    .open > .dropdown-toggle.btn-pure.focus,
    .btn-pure[disabled].focus,
    fieldset[disabled] .btn-pure.focus {
        background-color: transparent;
        border-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .btn-pure.btn-default {
        color: #a3afb7;
    }

        .btn-pure.btn-default:hover,
        .btn-pure.btn-default:focus,
        .btn-pure.btn-default:active,
        .btn-pure.btn-default.active,
        .open > .dropdown-toggle.btn-pure.btn-default {
            color: #526069;
        }

            .btn-pure.btn-default:hover:hover,
            .btn-pure.btn-default:focus:hover,
            .btn-pure.btn-default:active:hover,
            .btn-pure.btn-default.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-default:hover,
            .btn-pure.btn-default:hover:focus,
            .btn-pure.btn-default:focus:focus,
            .btn-pure.btn-default:active:focus,
            .btn-pure.btn-default.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-default:focus,
            .btn-pure.btn-default:hover.focus,
            .btn-pure.btn-default:focus.focus,
            .btn-pure.btn-default:active.focus,
            .btn-pure.btn-default.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-default.focus {
                color: #526069;
            }

            .btn-pure.btn-default:hover .badge,
            .btn-pure.btn-default:focus .badge,
            .btn-pure.btn-default:active .badge,
            .btn-pure.btn-default.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-default .badge {
                color: #526069;
            }

    .btn-pure.btn-primary {
        color: #62a8ea;
    }

        .btn-pure.btn-primary:hover,
        .btn-pure.btn-primary:focus,
        .btn-pure.btn-primary:active,
        .btn-pure.btn-primary.active,
        .open > .dropdown-toggle.btn-pure.btn-primary {
            color: #3583ca;
        }

            .btn-pure.btn-primary:hover:hover,
            .btn-pure.btn-primary:focus:hover,
            .btn-pure.btn-primary:active:hover,
            .btn-pure.btn-primary.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-primary:hover,
            .btn-pure.btn-primary:hover:focus,
            .btn-pure.btn-primary:focus:focus,
            .btn-pure.btn-primary:active:focus,
            .btn-pure.btn-primary.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-primary:focus,
            .btn-pure.btn-primary:hover.focus,
            .btn-pure.btn-primary:focus.focus,
            .btn-pure.btn-primary:active.focus,
            .btn-pure.btn-primary.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-primary.focus {
                color: #3583ca;
            }

            .btn-pure.btn-primary:hover .badge,
            .btn-pure.btn-primary:focus .badge,
            .btn-pure.btn-primary:active .badge,
            .btn-pure.btn-primary.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-primary .badge {
                color: #3583ca;
            }

    .btn-pure.btn-success {
        color: #46be8a;
    }

        .btn-pure.btn-success:hover,
        .btn-pure.btn-success:focus,
        .btn-pure.btn-success:active,
        .btn-pure.btn-success.active,
        .open > .dropdown-toggle.btn-pure.btn-success {
            color: #279566;
        }

            .btn-pure.btn-success:hover:hover,
            .btn-pure.btn-success:focus:hover,
            .btn-pure.btn-success:active:hover,
            .btn-pure.btn-success.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-success:hover,
            .btn-pure.btn-success:hover:focus,
            .btn-pure.btn-success:focus:focus,
            .btn-pure.btn-success:active:focus,
            .btn-pure.btn-success.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-success:focus,
            .btn-pure.btn-success:hover.focus,
            .btn-pure.btn-success:focus.focus,
            .btn-pure.btn-success:active.focus,
            .btn-pure.btn-success.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-success.focus {
                color: #279566;
            }

            .btn-pure.btn-success:hover .badge,
            .btn-pure.btn-success:focus .badge,
            .btn-pure.btn-success:active .badge,
            .btn-pure.btn-success.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-success .badge {
                color: #279566;
            }

    .btn-pure.btn-info {
        color: #57c7d4;
    }

        .btn-pure.btn-info:hover,
        .btn-pure.btn-info:focus,
        .btn-pure.btn-info:active,
        .btn-pure.btn-info.active,
        .open > .dropdown-toggle.btn-pure.btn-info {
            color: #37a9b7;
        }

            .btn-pure.btn-info:hover:hover,
            .btn-pure.btn-info:focus:hover,
            .btn-pure.btn-info:active:hover,
            .btn-pure.btn-info.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-info:hover,
            .btn-pure.btn-info:hover:focus,
            .btn-pure.btn-info:focus:focus,
            .btn-pure.btn-info:active:focus,
            .btn-pure.btn-info.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-info:focus,
            .btn-pure.btn-info:hover.focus,
            .btn-pure.btn-info:focus.focus,
            .btn-pure.btn-info:active.focus,
            .btn-pure.btn-info.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-info.focus {
                color: #37a9b7;
            }

            .btn-pure.btn-info:hover .badge,
            .btn-pure.btn-info:focus .badge,
            .btn-pure.btn-info:active .badge,
            .btn-pure.btn-info.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-info .badge {
                color: #37a9b7;
            }

    .btn-pure.btn-warning {
        color: #f2a654;
    }

        .btn-pure.btn-warning:hover,
        .btn-pure.btn-warning:focus,
        .btn-pure.btn-warning:active,
        .btn-pure.btn-warning.active,
        .open > .dropdown-toggle.btn-pure.btn-warning {
            color: #e98f2e;
        }

            .btn-pure.btn-warning:hover:hover,
            .btn-pure.btn-warning:focus:hover,
            .btn-pure.btn-warning:active:hover,
            .btn-pure.btn-warning.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-warning:hover,
            .btn-pure.btn-warning:hover:focus,
            .btn-pure.btn-warning:focus:focus,
            .btn-pure.btn-warning:active:focus,
            .btn-pure.btn-warning.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-warning:focus,
            .btn-pure.btn-warning:hover.focus,
            .btn-pure.btn-warning:focus.focus,
            .btn-pure.btn-warning:active.focus,
            .btn-pure.btn-warning.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-warning.focus {
                color: #e98f2e;
            }

            .btn-pure.btn-warning:hover .badge,
            .btn-pure.btn-warning:focus .badge,
            .btn-pure.btn-warning:active .badge,
            .btn-pure.btn-warning.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-warning .badge {
                color: #e98f2e;
            }

    .btn-pure.btn-danger {
        color: #f96868;
    }

        .btn-pure.btn-danger:hover,
        .btn-pure.btn-danger:focus,
        .btn-pure.btn-danger:active,
        .btn-pure.btn-danger.active,
        .open > .dropdown-toggle.btn-pure.btn-danger {
            color: #d6494b;
        }

            .btn-pure.btn-danger:hover:hover,
            .btn-pure.btn-danger:focus:hover,
            .btn-pure.btn-danger:active:hover,
            .btn-pure.btn-danger.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-danger:hover,
            .btn-pure.btn-danger:hover:focus,
            .btn-pure.btn-danger:focus:focus,
            .btn-pure.btn-danger:active:focus,
            .btn-pure.btn-danger.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-danger:focus,
            .btn-pure.btn-danger:hover.focus,
            .btn-pure.btn-danger:focus.focus,
            .btn-pure.btn-danger:active.focus,
            .btn-pure.btn-danger.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-danger.focus {
                color: #d6494b;
            }

            .btn-pure.btn-danger:hover .badge,
            .btn-pure.btn-danger:focus .badge,
            .btn-pure.btn-danger:active .badge,
            .btn-pure.btn-danger.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-danger .badge {
                color: #d6494b;
            }

    .btn-pure.btn-dark {
        color: #526069;
    }

        .btn-pure.btn-dark:hover,
        .btn-pure.btn-dark:focus,
        .btn-pure.btn-dark:active,
        .btn-pure.btn-dark.active,
        .open > .dropdown-toggle.btn-pure.btn-dark {
            color: #37474f;
        }

            .btn-pure.btn-dark:hover:hover,
            .btn-pure.btn-dark:focus:hover,
            .btn-pure.btn-dark:active:hover,
            .btn-pure.btn-dark.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-dark:hover,
            .btn-pure.btn-dark:hover:focus,
            .btn-pure.btn-dark:focus:focus,
            .btn-pure.btn-dark:active:focus,
            .btn-pure.btn-dark.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-dark:focus,
            .btn-pure.btn-dark:hover.focus,
            .btn-pure.btn-dark:focus.focus,
            .btn-pure.btn-dark:active.focus,
            .btn-pure.btn-dark.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-dark.focus {
                color: #37474f;
            }

            .btn-pure.btn-dark:hover .badge,
            .btn-pure.btn-dark:focus .badge,
            .btn-pure.btn-dark:active .badge,
            .btn-pure.btn-dark.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-dark .badge {
                color: #37474f;
            }

    .btn-pure.btn-inverse {
        color: #fff;
    }

        .btn-pure.btn-inverse:hover,
        .btn-pure.btn-inverse:focus,
        .btn-pure.btn-inverse:active,
        .btn-pure.btn-inverse.active,
        .open > .dropdown-toggle.btn-pure.btn-inverse {
            color: #fff;
        }

            .btn-pure.btn-inverse:hover:hover,
            .btn-pure.btn-inverse:focus:hover,
            .btn-pure.btn-inverse:active:hover,
            .btn-pure.btn-inverse.active:hover,
            .open > .dropdown-toggle.btn-pure.btn-inverse:hover,
            .btn-pure.btn-inverse:hover:focus,
            .btn-pure.btn-inverse:focus:focus,
            .btn-pure.btn-inverse:active:focus,
            .btn-pure.btn-inverse.active:focus,
            .open > .dropdown-toggle.btn-pure.btn-inverse:focus,
            .btn-pure.btn-inverse:hover.focus,
            .btn-pure.btn-inverse:focus.focus,
            .btn-pure.btn-inverse:active.focus,
            .btn-pure.btn-inverse.active.focus,
            .open > .dropdown-toggle.btn-pure.btn-inverse.focus {
                color: #fff;
            }

            .btn-pure.btn-inverse:hover .badge,
            .btn-pure.btn-inverse:focus .badge,
            .btn-pure.btn-inverse:active .badge,
            .btn-pure.btn-inverse.active .badge,
            .open > .dropdown-toggle.btn-pure.btn-inverse .badge {
                color: #fff;
            }
.ReactTable div.rt-resizer {
    z-index: auto;
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.btnPosition {
    position: relative;
    z-index: 1041
}
.zindexBanner{
    z-index:1042;
}
.rt-noData {
    display: none !important;
}
ul.ColVis_collection {
    padding: 8px 0px 4px 18px;
    width: auto;
}

    ul.ColVis_collection li {
        width: 100%;
        max-width: 32%;
    }

.center-modal {
    top: 20%;
}

    .center-modal > .modal-dialog {
        width: 60%;
    }
    .textalign-left{
        text-align:left;
    }

    .textalign-right {
        text-align: right;
    }
.scrollTop {
    position: absolute;
    right: 20px;
    bottom: 0px;
    background: #b2b2b2;
    background: rgba(178, 178, 178, 0.7);
    padding: 20px 25px;
    text-align: center;
    margin: 0 0 0 0;
    cursor: pointer;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
}

.scrolltopDiv {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 125px;
    right: 10px;
}  


.no-browser-support {
    font-size: 20px;
    text-align: center;
    max-width: 570px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -65px 0 0 -285px;
}

/*react navigation styles*/
.react-datepicker__header {
    padding-top: 0 !important;
    padding: 2px !important;
    background-color: #fff;
    border-bottom: none;
}

.react-datepicker {
    border-radius: 5px;
}

.react-datepicker__current-month {
    background-color: #5c9ccc;
    border-radius: 5px;
    min-height: 30px;
    color: #fff;
    padding-top: 7px;
    font-size: 12px !important;
}

.react-datepicker__navigation--previous:before, .react-datepicker__navigation--next:before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 20px;
    background-color: #d8e7f3;
    position: absolute;
    z-index: 99;
    top: -7px;
    left: -5px;
}

.react-datepicker__navigation--next:before {
    left: inherit;
    right: -4px;
    top: -7px;
}
.react-datepicker__navigation--previous:after, .react-datepicker__navigation--next:after {
    content: "";
    border: 3.5px solid transparent;
    border-right-color: #5c9ccc;
    width: 5px;
    height: 5px;
    position: absolute;
    z-index: 9999;
    left: -4px;
    top: -4px;
}
.react-datepicker__navigation--next:after {
    border-left-color: #5c9ccc;
    border-right-color:transparent;
}
.react-datepicker__navigation {
    z-index: 999;
    overflow: visible;
    border-width: 0;
}

.react-datepicker__day-names, .react-datepicker__week {
    font-size: 11px;
    font-weight: 700;
}

.react-datepicker__month .react-datepicker__day {
    border: 1px solid #c5dbec;
    background-color: #dfeffc;
    color: #2e6e9e;
    margin: 1.2px !important;
    width: 27px;
    height: 22px;
    border-radius: 0;
    text-align: right;
    padding: 2px 2px 0 0;
}

.react-datepicker__day--today {
    border: 1px solid #fad42e !important;
    background-color: #fbec88 !important;
    color: #363636 !important;
}

.react-datepicker__day--selected {
    color: #e17009 !important;
    background-color: #f5f8f9 !important;
    border-color: #79b7e7;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    display: none;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 1px;
}

.react-datepicker__navigation--previous {
    border-right-color: #5c9ccc;
}

.react-datepicker__navigation--next {
    border-left-color: #5c9ccc;
    right: 11px;
}

.react-datepicker__navigation {
    height: 5px;
    width: 5px;
    border-width: 0.35rem;
    top: 14px !important;
}

.react-datepicker__navigation--previous {
    left: 12px;
}

.react-datepicker__month .react-datepicker__day:hover {
    background-color: #d0e5f5;
    color: #1d5987;
    border: 1px solid #79b7e7;
}

.react-datepicker__month .react-datepicker__day.react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
    background-color: transparent;
    border: none;
}

.react-datepicker__month .react-datepicker__day.react-datepicker__day--disabled {
    opacity: 0.4;
}

.form-control.invalid-data {
    border-color: #a94442
}
